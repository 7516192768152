import React from "react";
import aus from './Australia.module.css';
import Navbar from "../navbar/Navbar";
import kan from'./images/kangaroo.jpg';
import emu from './images/emu.jpg';
import ko from './images/koalab.jpg';
import kiw from './images/kiwi.jpg';
import cas from './images/cas.jpg'
function Australia(){
    return(
        <>
        <Navbar/>
        <div className='afb6'>
<h1 className='bannereu'> <br /> <br /> <br />Explore Australian Wildlife</h1>
</div>
<div className={aus.first}>
       
       <br /><br />
       <br />
       <center><img src={kan} className={aus.imga} /></center>
              <br /><br />
       
              <h1 className={aus.hed}> Kangaroo
</h1>
              <br /><br />
              <p className={aus.para}> Kangaroo is a majestic marsupial. It is the national animal of Australia. There are four species of Kangaroos - Red Kangaroo, Eastern Grey Kangaroo, Antilopine Kangaroo and Western Grey Kangaroo. The red kangaroos are the largest and the Western Grey Kangaroo is the smallest. It is known for its efficient way of moving - hopping, Kangaroos have strong hindlimbs which have help them to make high hops, Kangaroos are also known for their front opening pouch, where they carry their babies.
</p>
           <div className={aus.cord}>
                   
                    <div className={aus.bgc2}>
                <h1 className={aus.title}> <b> Characteristics </b></h1> <br />
              
               <p className={aus.bd}>  Mode of nutrition:  Herbivore  </p> <br />
               
               
               <p className={aus.bd}>   Scientific Name:  Macropus
</p> <br />
                
               
               <p className={aus.bd}>  IUCN Status: Least Concern
                </p> <br />
                <p className={aus.bd}>  Range:  Australia

</p>
<br /><br />
               </div> 
               <div className={aus.s}></div>
               </div></div>
       
               <div className={aus.first}>
       
       <br /><br />
       <br />
       <center><img src={emu} className={aus.imga} /></center>
              <br /><br />
       
              <h1 className={aus.hed}> Emu
</h1>
              <br /><br />
              <p className={aus.para}> Kangaroo is a majestic marsupial. It is the national animal of Australia. There are four species of Kangaroos - Red Kangaroo, Eastern Grey Kangaroo, Antilopine Kangaroo and Western Grey Kangaroo. The red kangaroos are the largest and the Western Grey Kangaroo is the smallest. It is known for its efficient way of moving - hopping, Kangaroos have strong hindlimbs which have help them to make high hops, Kangaroos are also known for their front opening pouch, where they carry their babies.

</p>
           <div className={aus.cord}>
                   
                    <div className={aus.bgc2}>
                <h1 className={aus.title}> <b> Characteristics </b></h1> <br />
              
               <p className={aus.bd}>  Mode of nutrition:  Herbivore  </p> <br />
               
               
               <p className={aus.bd}>   Scientific Name:  Dromaius novaehollandiae 
</p> <br />
                
               
               <p className={aus.bd}>  IUCN Status: Least Concern
                </p> <br />
                <p className={aus.bd}>  Range:  Australia

</p>
<br /><br />
               </div> 
               <div className={aus.s2}></div>
               </div></div>
       
  
               <div className={aus.first}>
       
       <br /><br />
       <br />
       <center><img src={cas} className={aus.imga} /></center>
              <br /><br />
       
              <h1 className={aus.hed}> Cassowary

</h1>
              <br /><br />
              <p className={aus.para}> The Cassowary is the heaviest in Australia and third heaviest in the world, it is a solitary flightless bird known for its aggressive looks. The Cassowary has three species - Southern Cassowary, Dwarf Cassowary and Northern Cassowary. it can easily be recognised by its blue and black feather, extremely long legs and a casque on their heads, called the helmet.


</p>
           <div className={aus.cord}>
                   
                    <div className={aus.bgc2}>
                <h1 className={aus.title}> <b> Characteristics </b></h1> <br />
              
               <p className={aus.bd}>  Mode of nutrition: Omnivore  </p> <br />
               
               
               <p className={aus.bd}>   Scientific Name:  Casuarius casuarius
</p> <br />
                
               
               <p className={aus.bd}>  IUCN Status: Least Concern 
                </p> <br />
                <p className={aus.bd}>  Range: Northern Australia and New Guinea

</p>
<br /><br />
               </div> 
               <div className={aus.s3}></div>
               </div></div>

             
  
               <div className={aus.first}>
       
       <br /><br />
       <br />
       <center><img src={ko} className={aus.imga} /></center>
              <br /><br />
       
              <h1 className={aus.hed}> Koala

</h1>
              <br /><br />
              <p className={aus.para}> The Koala is a charming arboreal mammal native to Australia. It is a marsupial known for its 'lazy reputation'. Koalas are professional in climbing trees. They have a round head, a fat and tail-less body with grayish-silver fur. They big and fluffy ears and a black nose. The Koala has strong limbs and sharp claws which help it in climbing trees in ease



</p>
           <div className={aus.cord}>
                   
                    <div className={aus.bgc2}>
                <h1 className={aus.title}> <b> Characteristics </b></h1> <br />
              
               <p className={aus.bd}>  Mode of nutrition: Herbivore  </p> <br />
               
               
               <p className={aus.bd}>   Scientific Name:  Phascolarctos cinereus 
</p> <br />
                
               
               <p className={aus.bd}>  IUCN Status:  Vulnerable 
                </p> <br />
                <p className={aus.bd}>  Range:  Eastern and Southeastern Australia

</p>
<br /><br />
               </div> 
               <div className={aus.s4}></div>
               </div></div>

             
           
        </>
    );
}
export default Australia;