import React from "react";
import ant from  './Antarctica.module.css'
import Navbar from "../navbar/Navbar";
import ad from "./images/aap.jpg";
import emp from './images/empo2.jpg';
import ele from './images/ele.jpg';
import le from './images/leoo.jpg';
import wod from './images/weda.jpg';
import './banners.css';


function Antarctica(){
    return(
        <div>
            <Navbar/>
            <div className='afb5'>
<h1 className='bannereu'> <br /> <br /><br /> Explore Antarctic Wildlife</h1>
</div>
<div className={ant.first}>
       
       <br /><br />
       <br />
       <center><img src={ad} className={ant.imga} /></center>
              <br /><br />
       
              <h1 className={ant.hed}>  Adélie Penguin</h1>
              <br /><br />
              <p className={ant.para}> The Adélie Penguin is an adaptable and charming flightless bird. It features a black and white streamlined body, a black head, a white front and a black back. It has white eye rings surrounding its eyes and a orange - yellow beak. The Adélie Penguin is an excellent swimmer aswell as diver and can speedily swim through icy waters, the bird can dive deep upto 150cm by holding breath for upto six minutes.



</p>
           <div className={ant.cord}>
                   
                    <div className={ant.bgc2}>
                <h1 className={ant.title}> <b> Characteristics </b></h1> <br />
              
               <p className={ant.bd}>  Mode of nutrition:  Carnivore  </p> <br />
               
               
               <p className={ant.bd}>   Scientific Name:  Pygoscelis adeliae
</p> <br />
                
               
               <p className={ant.bd}>  IUCN Status: Least Concern
                </p> <br />
                <p className={ant.bd}>  Range:  Antarctic Coast

</p>
<br /><br />
               </div> 
               <div className={ant.s}></div>
               </div></div>
  
               <div className={ant.first}>
       
       <br /><br />
       <br />
       <center><img src={emp} className={ant.imga} /></center>
              <br /><br />
       
              <h1 className={ant.hed}>  Emperor 
 Penguin</h1>
              <br /><br />
              <p className={ant.para}>  The Emperor penguin is the largest in the species. 
              It is a majestic and iconic bird. Like the other penguins, it has a streamlined body. 
              It can dive the deepest and is also known as the "Olympic Diver" of the bird world with highest recorded dive of 564 m!
               The Emperor penguin has a black head and back plumage, white front plumage, a yellow-orange patch of skin around their necks.




</p>
           <div className={ant.cord}>
                   
                    <div className={ant.bgc2}>
                <h1 className={ant.title}> <b> Characteristics </b></h1> <br />
              
               <p className={ant.bd}>  Mode of nutrition:  Carnivore  </p> <br />
               
               
               <p className={ant.bd}>   Scientific Name:  Aptenodytes forsteri
</p> <br />
                
               
               <p className={ant.bd}>  IUCN Status: Near Threatened 
                </p> <br />
                <p className={ant.bd}>  Range:  Antarctic Coast

</p>
<br /><br />
               </div> 
               <div className={ant.s2}></div>
               </div></div>
  
           
               <div className={ant.first}>
       
       <br /><br />
       <br />
       <center><img src={wod} className={ant.imga} /></center>
              <br /><br />
       
              <h1 className={ant.hed}>  Weddell seal
 
 </h1>
              <br /><br />
              <p className={ant.para}> The weddell seal is a professional diver, it can be underwater with depth of 600 meters for more than an hour. The weddel seal is a true seal. It has a grayish-dark brown coat, whiskers and dark colored spots around its mouth. It has strong hind flippers and forelimbs can swim speedily in cold and icy waters.






</p>
           <div className={ant.cord}>
                   
                    <div className={ant.bgc2}>
                <h1 className={ant.title}> <b> Characteristics </b></h1> <br />
              
               <p className={ant.bd}>  Mode of nutrition:  Carnivore  </p> <br />
               
               
               <p className={ant.bd}>   Scientific Name:  Leptonychotes weddellii
</p> <br />
                
               
               <p className={ant.bd}>  IUCN Status: Least Concerned
                </p> <br />
                <p className={ant.bd}>  Range:  Antarctic Coast

</p>
<br /><br />
               </div> 
               <div className={ant.s3}></div>
               </div></div>
  
           
          
        
     
           
       </div>
    )
}
export default Antarctica;