import React from "react";
import big from './Aelep.module.css';
import eleo from './images/aelep1.jpg';
import animalia from "./Animal.module.css";
import liona from './Animal.module.css'
import r from './Arhino.module.css';
import aee from './images/aarh.jpg';
import chet from './Cheet.module.css';
import howl from './Fennec.module.css';
import barks from './Hunting.module.css';
import I from './Imap.module.css';
import imp from './images/impa.jpg';
import e from './Str.module.css';
import ze from './Ze.module.css';
import za from './images/zea.jpg';
import fenc from './images/fennec.jpg';
import che from './images/cheet.jpg';
import Navbar from "../navbar/Navbar";
import afr2 from './images/af2.jpg';
import lio from './images/lio.jpg';
import gir from './images/gir.jpg';
import str from './images/Striped.jpg';
import './banners.css';
import './giraf.css'
function Africa(){
    return(
      <>
    <div>
        <Navbar/>
        <div className='afb2'>
<h1 className='aafb'> <br /> <br /> Explore African Wildlife</h1>
</div>

<div className={animalia.first}>
       
       <br /><br />
       <br />
       <center><img src={lio} className={liona.imga} /></center>
              <br /><br />
       
              <h1 className={liona.hed}> Lion</h1>
              <br /><br />
              <p className={liona.para}> The lion is one of the most iconic animals in the world,
               it is also known as the king of the jungle. The lion is a very powerful and robust mammal.
                It is well known for its hunting abilities. The male lion's golden-yellow coat feature a mane impressive mane, a thick growth of hair encircling
               the head and neck which is dark brown - black in color. The females lack a mane and have more sleeker and slender body and head. </p>   
           <div className={liona.cord}>
                   
                    <div className={liona.bgc}>
                <h1 className={liona.title}> <b> Characteristics </b></h1> <br />
              
               <p className={liona.bd}>  Mode of nutrition:  Carnivorous  </p> <br />
               
               
               <p className={liona.bd}>  Scientific Name: Panthera leo</p> <br />
                
               
               <p className={liona.bd}>
        IUCN Status: Vulnerable 
                </p> <br />
                <p className={liona.bd}> Range: Central Africa and northwestern India</p>
               
            
               <br /><br />
               </div> 
               <div className={liona.s}></div>
               
               
           </div>
           </div>
           </div>
         
           <div className={animalia.first}>
       
       <br /><br />
       <br />
       <center><img src={gir} className={liona.imga} /></center>
              <br /><br />
       
              <h1 className={liona.hed}> Giraffe</h1>
              <br /><br />
              <p className={liona.para}> The Giraffe is the tallest mammal in the world,
               it is native to Africa. It is well known for its long neck, legs and its spotted coat. 
               Its coat has a yellow-goldish color with brown. It has brown colored hair over its neck.

</p>   
           <div className={liona.cordg}>
                   
                    <div className={liona.bgc2}>
                <h1 className={liona.title}> <b> Characteristics </b></h1> <br />
              
               <p className={liona.bd}>  Mode of nutrition:  Herbivorous  </p> <br />
               
               
               <p className={liona.bd}>   Scientific Name: Giraffa camelopardalis 
</p> <br />
                
               
               <p className={liona.bd}>  IUCN Status: Vulnerable 
                </p> <br />
                <p className={liona.bd}>  Range: Central Africa and Southern Africa 

</p>
               
            
               <br /><br />
               </div> 
               <div className="s"></div>
               
               
           </div>
           </div>

           <div className={animalia.first}>
       
       <br /><br />
       <br />
       <center><img src={afr2} className={liona.imga} /></center>
              <br /><br />
       
              <h1 className={liona.hed}> African Hunting Dog </h1>
              <br /><br />
              <p className={liona.para}> The hunting dog is an amazing predator, it lives and hunts in groups called packs and is very robust. It is well known for its colorful fur which is a mix of yellow, black and white.

</p>
           <div className={liona.cordg}>
                   
                    <div className={liona.bgc2}>
                <h1 className={liona.title}> <b> Characteristics </b></h1> <br />
              
               <p className={liona.bd}>  Mode of nutrition:  Carnivore  </p> <br />
               
               
               <p className={liona.bd}>   Scientific Name: Lycaon pictus 
</p> <br />
                
               
               <p className={liona.bd}>  IUCN Status: endangered  
                </p> <br />
                <p className={liona.bd}>  Range: Central Africa, South Africa 

</p>
<br /><br />
               </div> 
               <div className="s2"></div>
               
               
           </div>
           </div>
           <div className={animalia.first}>
       
       <br /><br />
       <br />
       <center><img src={str} className={e.imga} /></center>
              <br /><br />
       
              <h1 className={e.hed}> Striped Hyena</h1>
              <br /><br />
              <p className={e.para}> The hunting dog is an amazing predator, it lives and hunts in groups called packs and is very robust. It is well known for its colorful fur which is a mix of yellow, black and white.

</p>
           <div className={e.cord}>
                   
                    <div className={e.bgc2}>
                <h1 className={liona.title}> <b> Characteristics </b></h1> <br />
              
               <p className={liona.bd}>  Mode of nutrition:  Carnivore  </p> <br />
               
               
               <p className={liona.bd}>   Scientific Name: Hyaena hyaena 
</p> <br />
                
               
               <p className={liona.bd}>  IUCN Status: Near Threatened
                </p> <br />
                <p className={liona.bd}>  Range:  Africa, India  

</p>
<br /><br />
               </div> 
               <div className={e.s}></div>
               
               
           </div>
           </div>
           <div className={animalia.first}>
       
       <br /><br />
       <br />
       <center><img src={che} className={e.imga} /></center>
              <br /><br />
       
              <h1 className={e.hed}> Cheetah</h1>
              <br /><br />
              <p className={e.para}> The cheetah is the fastest mammal on earth native to Africa and Asia. 
              It is has a aerodynamic body and has yellow-goldish coat with black circular spots the cheetah. It is a powerful and beautiful animal

</p>
           <div className={chet.cord}>
                   
                    <div className={e.bgc2}>
                <h1 className={liona.title}> <b> Characteristics </b></h1> <br />
              
               <p className={liona.bd}>  Mode of nutrition:  Carnivore  </p> <br />
               
               
               <p className={liona.bd}>   Scientific Name: Acinonyx jubatus  
</p> <br />
                
               
               <p className={liona.bd}>  IUCN Status: Vulnerable
                </p> <br />
                <p className={liona.bd}>  Range: Central Africa , Asia 

</p>
<br /><br />
               </div> 
               <div className={chet.s}></div>
               
               
           </div>
           </div>

           <div className={animalia.first}>
       
       <br /><br />
       <br />
       <center><img src={fenc} className={e.imga} /></center>
              <br /><br />
       
              <h1 className={e.hed}> Fennec fox</h1>
              <br /><br />
              <p className={e.para}> The fennec fox is a small mammal native to North Africa and Southwestern Asia it has goldish sand colored fur coat which works as a camouflage in the desert. It is a noctural animal.

</p>
           <div className={chet.cord}>
                   
                    <div className={e.bgc2}>
                <h1 className={liona.title}> <b> Characteristics </b></h1> <br />
              
               <p className={liona.bd}>  Mode of nutrition:  Carnivore  </p> <br />
               
               
               <p className={liona.bd}>   Scientific Name: Vulpes zerda
</p> <br />
                
               
               <p className={liona.bd}>  IUCN Status: Least Concern
                </p> <br />
                <p className={liona.bd}>  Range: Central Africa , Asia 

</p>
<br /><br />
               </div> 
               <div className={howl.s}></div>
               
               
           </div>
           </div>

           <div className={animalia.first}>
       
       <br /><br />
       <br />
       <center><img src={eleo} className={e.imga} /></center>
              <br /><br />
       
              <h1 className={e.hed}> African elephant</h1>
              <br /><br />
              <p className={e.para}> It is the largest land mammal. It is a beautiful and majestic animal. It has a large and long trunk (elephants's nose) which is used for holding food,
               for producing loud trumpets and breathing. It uses its tusks for digging, fighting and stripping bark of trees.

</p>
           <div className={chet.cord}>
                   
                    <div className={e.bgc2}>
                <h1 className={liona.title}> <b> Characteristics </b></h1> <br />
              
               <p className={liona.bd}>  Mode of nutrition:  Herbivore  </p> <br />
               
               
               <p className={liona.bd}>   Scientific Name: Loxodonta
</p> <br />
                
               
               <p className={liona.bd}>  IUCN Status:  endangered
                </p> <br />
                <p className={liona.bd}>  Range: sub-Saharan Africa, Central and West Africa

</p>
<br /><br />
               </div> 
               <div className={big.s}></div>
               </div>
               </div>

 
               <div className={animalia.first}>
       
       <br /><br />
       <br />
       <center><img src={aee} className={e.imga} /></center>
              <br /><br />
       
              <h1 className={e.hed}> Black Rhinoceros</h1>
              <br /><br />
              <p className={e.para}> lack Rhinoceros is a rare species of Rhinos. Black Rhinceros have a bad vision but have a good sense of smell and hearing. They are also known as hook-lipped
               Rhinos because of their upper lip which helps them to grab food such as leaves, fruits from small trees and shoots from bushes

</p>
           <div className={r.cord}>
                   
                    <div className={e.bgc2}>
                <h1 className={liona.title}> <b> Characteristics </b></h1> <br />
              
               <p className={liona.bd}>  Mode of nutrition:  Herbivore  </p> <br />
               
               
               <p className={liona.bd}>   Scientific Name: Diceros bicarnis
</p> <br />
                
               
               <p className={liona.bd}>  IUCN Status: Critically endangered
                </p> <br />
                <p className={liona.bd}>  Range: eastern and Western Africa

</p>
<br /><br />
               </div> 
               <div className={r.s}></div>
               
               
           </div>
           </div>
           <div className={animalia.first}>
       
       <br /><br />
       <br />
       <center><img src={za} className={e.imga} /></center>
              <br /><br />
       
              <h1 className={e.hed}> Plains Zebra</h1>
              <br /><br />
              <p className={e.para}> Zebras have three species, the plains zebra,the mountain zebra and the Grévy's zebra. 
              They are known for their beautiful stripes on their body, the stripes pattern is unique in each individual.
               The stripes act as a camouflage against predators. Zebras are fast runner, they can run fast as 65km/h.
               Zebras like to live in herds except the Grévy's zebra. Zebras can sleep while standing. The plains Zebra is the most common species
</p>
           <div className={ze.cord}>
                   
                    <div className={e.bgc2}>
                <h1 className={liona.title}> <b> Characteristics </b></h1> <br />
              
               <p className={liona.bd}>  Mode of nutrition:  Herbivore  </p> <br />
               
               
               <p className={liona.bd}>   Scientific Name: equus quagga
</p> <br />
                
               
               <p className={liona.bd}>  IUCN Status: Near Threatened
                </p> <br />
                <p className={liona.bd}>  Range: eastern and Southern Africa

</p>
<br /><br />
               </div> 
               <div className={ze.s}></div>
               
               
           </div>
           </div>
           <div className={animalia.first}>
       
       <br /><br />
       <br />
       <center><img src={imp} className={e.imga} /></center>
              <br /><br />
       
              <h1 className={e.hed}> Impala</h1>
              <br /><br />
              <p className={e.para}>Impalas are strikingly beautiful animals, they have a glossy reddish-brown coat, They are speedy animals with speed upto 90km/h. They can jump to almost 10 metres when running. 
              Impala has long spiral shaped horns which is used to challenge rivals aswell scare the rivals away from its territory
</p>
           <div className={I.cord}>
                   
                    <div className={e.bgc2}>
                <h1 className={liona.title}> <b> Characteristics </b></h1> <br />
              
               <p className={liona.bd}>  Mode of nutrition:  Herbivore  </p> <br />
               
               
               <p className={liona.bd}>   Scientific Name:  Aepyceros melampus
</p> <br />
                
               
               <p className={liona.bd}>  IUCN Status:  Least Concern
          
                </p> <br />
                <p className={liona.bd}>  Range: Southern Africa 

</p>
<br /><br />
               </div> 
               <div className={I.s}></div>
               
               
           </div>
           </div>
      



</>
    );
}

export default Africa;