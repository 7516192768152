import React from "react";
import Navbar from "../navbar/Navbar";
import bi from './bi.module.css';
import m from './moose.module.css';
import w from './wap.module.css';
import ox from './ox.module.css'
import bis from './images/bi.jpg';
import ma from './images/m.jpg';
import wapi from './images/wa.jpg';
import o from './images/ox2.jpg';
import vic from './images/vic.jpg';
import vica from './vic.module.css';
import './banners.css';
function America(){
    return(
<>
    <Navbar/>
    <div className='afb3'>
<h1 className='bannereu'> <br /> <br /> <br /> Explore American Wildlife</h1>
</div>
<div className={bi.first}>
       
       <br /><br />
       <br />
       <center><img src={bis} className={bi.imga} /></center>
              <br /><br />
       
              <h1 className={bi.hed}>  American Bison   </h1>
              <br /><br />
              <p className={bi.para}> The American Bison or American Buffalo is an iconic and a majestic animal. It is the national mammal of the United States and is also a symbol of American identity. The American Bison has a dark brown color. It is a powerful animal and can resist and adapt to extreme environmental conditions, it has a large hump over its front shoulders. Both male and female bison posseses curved horns which are used to defend itself from predators and also to present its dominance in the herd and scare its rivals.


</p>
           <div className={bi.cord}>
                   
                    <div className={bi.bgc2}>
                <h1 className={bi.title}> <b> Characteristics </b></h1> <br />
              
               <p className={bi.bd}>  Mode of nutrition:  Herbivore  </p> <br />
               
               
               <p className={bi.bd}>   Scientific Name:  Bison bison
</p> <br />
                
               
               <p className={bi.bd}>  IUCN Status: Critically Depleted
                </p> <br />
                <p className={bi.bd}>  Range:  North America

</p>
<br /><br />
               </div> 
               <div className={bi.s}></div>
               </div></div>
              
               <div className={bi.first}>
       
       <br /><br />
       <br />
       <center><img src={ma} className={bi.imga} /></center>
              <br /><br />
       
               <h1 className={bi.hed}>   Moose   </h1>
              <br /><br />
              <p className={bi.para}> The moose is the largest in the deer family. The moose has a dark-brown coat, it has long and large antlers and a flap of skin swinging down its throat It is a solitary animal and are calm but if threatened, it can be extremely aggressive. They are excellent swimmers and like to feed on aquatic plants in summer


</p>
           <div className={bi.cord}>
                   
                    <div className={bi.bgc2}>
                <h1 className={bi.title}> <b> Characteristics </b></h1> <br />
              
               <p className={bi.bd}>  Mode of nutrition:  Herbivore  </p> <br />
               
               
               <p className={bi.bd}>   Scientific Name:  Alces alces  
</p> <br />
                
               
               <p className={bi.bd}>  IUCN Status: Least Concern
                </p> <br />
                <p className={bi.bd}>  Range:  North America, Asia and Europe

</p>
<br /><br />
               </div> 
               <div className={bi.s2}></div>
      
   </div>
             
   <div className={bi.first}>
       
       <br /><br />
       <br />
       <center><img src={o} className={bi.imga} /></center>
              <br /><br />
       
               <h1 className={bi.hed}>   Musk-ox
   </h1>
              <br /><br />
              <p className={bi.para}> Musk ox is massive hoofed mammal inhabiting Arctic regions, it has a thick dark-brown coat which consists of a rough outer layer over a softer underlayer called qiviut, it is long haired, has curved horns and a very short tail. The animal is adapted and can resist extreme cold weather conditions due to its thick fur.



</p>
           <div className={bi.cord}>
                   
                    <div className={bi.bgc2}>
                <h1 className={bi.title}> <b> Characteristics </b></h1> <br />
              
               <p className={bi.bd}>  Mode of nutrition:  Herbivore  </p> <br />
               
               
               <p className={bi.bd}>   Scientific Name:  Ovibos moschatus  
</p> <br />
                
               
               <p className={bi.bd}>  IUCN Status: Least Concern
                </p> <br />
                <p className={bi.bd}>  Range:  Canada, Alaska, Greenland and Siberia 

</p>
<br /><br />
               </div> 
               <div className={bi.s3}></div>
      
   </div>
   </div>
   </div>
   <div className={bi.first}>
       
       <br /><br />
       <br />
       <center><img src={vic} className={bi.imga} /></center>
              <br /><br />
       
               <h1 className={bi.hed}>   Vicuña
   </h1>
              <br /><br />
              <p className={bi.para}> Vicuña is a small humpless camel inhabiting the high mountainS of the Andes mountain ranges. The animal has a thick goldish-brown fur which keeps it warm in cold environment of the Andes, it has dark and long eyelashes which give the animal a distinctive look. Vicuña is known for its soft hair, slim body, long neck short legs, and a small head.





</p>
           <div className={bi.cord}>
                   
                    <div className={bi.bgc2}>
                <h1 className={bi.title}> <b> Characteristics </b></h1> <br />
              
               <p className={bi.bd}>  Mode of nutrition:  Herbivore  </p> <br />
               
               
               <p className={bi.bd}>   Scientific Name:  Vicugna vicugna
</p> <br />
                
               
               <p className={bi.bd}>  IUCN Status: Moderately Depleted 
                </p> <br />
                <p className={bi.bd}>  Range:  Peru, Chile, Argentina, Bolivia and Ecuador

</p>
<br /><br />
               </div> 
               <div className={bi.s4}></div>
      
   </div>
   </div>

   </>

    )
   
}
export default America;