import  React, {useEffect, useRef, useState } from "react";
import Navbar from "../navbar/Navbar";
import asia from './Asia.module.css';
import ti from './Tiger.module.css';
import red from './red.module.css'
import  tig from './images/tigor.jpg';
import redp from './images/pand.jpg'
import giant from './gpan.module.css';
import lazy from './images/pandaa.jpg';
import ray from './Sun.module.css';
import sn from './snow.module.css';
import le from './le.module.css';
import r from './krhin.module.css';
import n from './images/snbg.jpg';
import h from './images/sun.jpg';
import hb from './images/sunb.jpg';
import l from './images/loo.jpg';
import rhh from './images/kz.jpg';
import tr from './Sum.module.css';
import jav from './java.module.css';
import tp from './tapir.module.css';
import su from './images/sumatran.jpg';
import ja from './images/jaav.jpg';
import tap from './images/tap.jpg';
import asiab from './banners/asia.jpg';
const Asia = () => {
  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);
    return(

        <>
<Navbar/>
<br />
<div className={asia.banner}>
<h1 className={asia.bant}> <br /> <br /> Explore Asian Wildlife</h1>
</div>

       <div className={ti.first}>
       
<br /><br />
<br />
<center><img src={tig} className={ti.imga} /></center>
       <br /><br />

       <h1 className={ti.hed}> Bengal Tiger</h1>
       <br /><br />
       <p className={ti.para}> When we talk about apex predators, it is impossible to not talk about tigers. 
        They are, without a doubt, regal and ferocious animals. 
        The Bengal Tiger is a sub-species of Tiger.  </p>   
    <div className={ti.cord}>
            
             <div className={ti.bgc}>
         <h1 className={ti.title}> <b> Characteristics </b></h1> <br />
       
        <p className={ti.bd}>  Mode of nutrition: Carnivorous </p> <br />
        
        
        <p className={ti.bd}>  Scientific Name: Panthera tigris tigris </p> <br />
         
        
        <p className={ti.bd}>
 IUCN Status: Endangered 
         </p> <br />
         <p className={ti.bd}> Range: India, Bangladesh, Bhutan, Nepal, Pakistan and Southwestern China</p>
        
     
        <br /><br />
        </div> 
        <div className={ti.s}></div>
        
        
    </div>
             
       </div>
     
       <div className={red.first}>
        <br /><br /> <br />
        <center> <img src={redp} alt="" className={red.imga}/></center>
        <br /><br />
        <h1 className={red.hed}> Red Panda</h1>
        <br /><br />
        <p className={red.para}> <b> The Red Panda </b> is an arboreal animal i.e it lives on trees, it is also known as lesser panda. The animal has a beautiful reddish-brown fur with a white face and a black fur around its eyes.
        The red panda sleeps during the day on the branches of trees and feeds during night, it mainly eats bamboo shoots. </p>
        <div></div>
        
    <div className={red.cord}>
            
             <div className={red.bgc}>
         <h1 className={red.title}> <b> Characteristics </b></h1> <br />
       
        <p className={red.bd}>  Mode of nutrition:  Omnivorous   </p> <br />
        
        
        <p className={red.bd}>  Scientific Name: Ailurus fulgens  </p> <br />
       
         
        
        <p className={red.bd}>
 IUCN Status: Endangered 
         </p> <br />
         <p className={red.bd}> Range: Nepal, northeastern India, southern Myanmar and southwestern China </p>
        
     
        <br /><br />
        </div> 

        <div className={red.s}></div>
          
      </div>
      </div>
    
      <div className={giant.first}>
        <br /><br /> <br />
        <center> <img src={lazy} alt="" className={giant.imga}/></center>
        <br /><br />
        <h1 className={red.hed}> Giant Panda</h1>
        <br /><br />
        <p className={giant.para}> Giant Panda or Panda is a charming and rare animal native to the mountain ranges of central China, it belongs to the bear family and is also called panda bear. The animal is easily recognisable by its round body, black and white color, black fur patches around its eyes, ears, shoulders, and legs. The Giant Panda primarily eats bamboos,for survival, it has to feed for upto 12 hours each day.

</p>
        <div></div>
        
    <div className={giant.cord}>
            
             <div className={giant.bgc}>
         <h1 className={giant.title}> <b> Characteristics </b></h1> <br />
       
        <p className={giant.bd}>  Mode of nutrition:  Herbivorous   </p> <br />
        
        
        <p className={giant.bd}>  Scientific Name: Ailuropoda melanoleuca  </p> <br />
       
         
        
        <p className={giant.bd}>
 IUCN Status: Vulnerable 
         </p> <br />
         <p className={giant.bd}> Range: Central China </p>
        
     
        <br /><br />
        </div> 

        <div className={giant.s}></div>
          
      </div>
      </div>
    
      <div className={ray.first}>
        <br /><br /><br />
        <center> <img src={h} alt="" className={ray.imga}/></center>
        <br /><br />
        <h1 className={red.hed}> Sun Bear</h1>
        <br /><br />
        <p className={ray.para}> 
        Sun Bear or Malayan Sun bear is the smallest in the bear species. The animal is well known for the yellow-golden crescent mark in its chest and its beautiful black fur. Its feeds consists of insects, honey, and termites which it digs out by using its strong curved claws. It is also called the Honey Bear
</p>
        <div></div>
        
    <div className={ray.cord}>
            
             <div className={ray.bgc}>
         <h1 className={ray.title}> <b> Characteristics </b></h1> <br />
       
        <p className={ray.bd}>  Mode of nutrition:  Omnivorous   </p> <br />
        
        
        <p className={ray.bd}>  Scientific Name: Helarctos malaynus   </p> <br />
       
         
        
        <p className={ray.bd}>
 IUCN Status: Vulnerable 
         </p> <br />
         <p className={ray.bd}> Range: Southeast Asia </p>
        
     
        <br /><br />
        </div> 

        <div className={ray.s}></div>
          
      </div>
      </div>
      <div className={sn.first}>
        <br /><br />
        <center> <img src={n} alt="" className={ray.imga}/></center>
        <br /><br /><br />
        <h1 className={red.hed}> Snow Leopard</h1>
        <br /><br />
        <p className={sn.para}> 
        Snow leopard is a big cat indigenous to the mountain ranges of Central and South Asia. It has a beautiful greyish - white fur which keeps it warm. It is a strong and good hunter, it mostly hunts wild sheep and goats

</p>
        <div></div>
        
    <div className={sn.cord}>
            
             <div className={sn.bgc}>
         <h1 className={sn.title}> <b> Characteristics </b></h1> <br />
       
        <p className={sn.bd}>  Mode of nutrition:  Carnivorous   </p> <br />
        
        
        <p className={ray.bd}>  Scientific Name: Panthera uncia    </p> <br />
       
         
        
        <p className={ray.bd}>
 IUCN Status: Vulnerable 
         </p> <br />
         <p className={ray.bd}> Range: South and Central Asia </p>
        
     
        <br /><br />
        </div> 

        <div className={sn.s}></div>
          
      </div>
      </div>

      <div className={le.first}>
        <br /><br />
        <center> <img src={l} alt="" className={ray.imga}/></center>
        <br /><br /><br />
        <h1 className={red.hed}> Leopard</h1>
        <br /><br />
        <p className={le.para}> 
        The Leopard is a majestic and beautiful big cat well-known for its climbing abilities, The animal tows the carcass of large prey into trees to prevent losing it to scavengers. It has a beautiful and thick golden-yellowish color fur with spots on it, the spots help it to camouflage among leaves or bushes or long grass. It is a professional hunters.

</p>
        <div></div>
        
    <div className={le.cord}>
            
             <div className={le.bgc}>
         <h1 className={le.title}> <b> Characteristics </b></h1> <br />
       
        <p className={ray.bd}>  Mode of nutrition:  Carnivorous   </p> <br />
        
        
        <p className={ray.bd}>  Scientific Name: Panthera pardus   </p> <br />
       
         
        
        <p className={ray.bd}>
 IUCN Status: Vulnerable 
         </p> <br />
         <p className={ray.bd}> Range: Southwestern and Southern Asia, Africa </p>
        
     
        <br /><br />
        </div> 

        <div className={le.s}></div>
          
      </div>
      </div>
      <div className={r.first}>
        <br /><br /><br />
        <center> <img src={rhh} alt="" className={r.imga}/></center>
        <br /><br />
        <h1 className={red.hed}> Greater One-horned Rhino</h1>
        <br /><br />
        <p className={r.para}> 
        The Greater One-horned Rhino or Indian Rhinoceros is the largest rhino out of the three Asian Rhinos. It is a one-horned Rhino. The animal has a thick greyish skin with deep folds and Wart-like bumps on its hind legs which appear like armor. it loves to bath in the mud which keeps it cool during summer

</p>
        <div></div>
        
    <div className={r.cord}>
            
             <div className={r.bgc}>
         <h1 className={ray.title}> <b> Characteristics </b></h1> <br />
       
        <p className={ray.bd}>  Mode of nutrition:  Herbivorous   </p> <br />
        
        
        <p className={ray.bd}>  Scientific Name:  Rhinoceros unicornis    </p> <br />
       
         
        
        <p className={ray.bd}>
 IUCN Status: Vulnerable 
         </p> <br />
         <p className={ray.bd}> Range: India, Nepal, Bhutan </p>
        
     
        <br /><br />
        </div> 

        <div className={r.s}></div>
          
      </div>
      </div>

      <div className={tr.first}>
        <br /><br /><br />
        <center> <img src={su} alt="" className={ray.imga}/></center>
        <br /><br />
        <h1 className={red.hed}> Sumatran rhinoceros</h1>
        <br /><br />
        <p className={ray.para}> 
        The Sumatran rhinoceros or Asian two-horned rhinoceros is a rare animal and is the smallest Rhino specie, it is extant only in Indonesia and is critically endangered. It is the only two-horned rhino in Asia. It has a hairy and brownish skin

</p>
        <div></div>
        
    <div className={tr.cord}>
            
             <div className={tr.bgc}>
         <h1 className={ray.title}> <b> Characteristics </b></h1> <br />
       
        <p className={ray.bd}>  Mode of nutrition:  Herbivorous    </p> <br />
        
        
        <p className={ray.bd}>  Scientific Name: Dicerorhinus sumatrensis    </p> <br />
       
         
        
        <p className={ray.bd}>
 IUCN Status: Critically Endangered 
         </p> <br />
         <p className={ray.bd}> Range: Indonesia</p>
        
     
        <br /><br />
        </div> 

        <div className={tr.s}></div>
          
      </div>
      </div>
      <div className={jav.first}>
        <br /><br /><br />
        <center> <img src={ja} alt="" className={ray.imga}/></center>
        <br /><br />
        <h1 className={red.hed}> Javan rhinoceros</h1>
        <br /><br />
        <p className={ray.para}> 
        The Javan rhinoceros is a rare animal and like the Sumatran Rhino, is extant only in some islands of Indonesia, it is the most threatened rhino species. It is one-horned and is also called the lesser one-horned rhinoceros. The animal has a grayish skin and lacks folds present in other rhino species

</p>
        <div></div>
        
    <div className={jav.cord}>
            
             <div className={ray.bgc}>
         <h1 className={ray.title}> <b> Characteristics </b></h1> <br />
       
        <p className={ray.bd}>  Mode of nutrition:  Herbivorous   </p> <br />
        
        
        <p className={ray.bd}>  Scientific Name: Rhinoceros sondaicus   </p> <br />
       
         
        
        <p className={ray.bd}>
 IUCN Status: Critically Endangered 
         </p> <br />
         <p className={ray.bd}> Range: Indonesia </p>
        
     
        <br /><br />
        </div> 

        <div className={jav.s}></div>
          
      </div>
      <div className={tp.first}>
        <br /><br /><br />
        <center> <img src={tap} alt="" className={ray.imga}/></center>
        <br /><br />
        <h1 className={red.hed}> Malayan Tapir </h1>
        <br /><br />
        <p className={ray.para}> 
        Malay Tapir is a unique animal inhabiting Southeast Asian countries of Indonesia, Malaysia, Myanmar and Thailand. The animal has a glossy black color on its head and legs and a white patch around the middle of its body. It posseses a long and highly flexible proboscis, it acts like a trunk and makes the animal able to grab/pluck fruits, leaves from trees

</p>
        <div></div>
        
    <div className={tp.cord}>
            
             <div className={tp.bgc}>
         <h1 className={ray.title}> <b> Characteristics </b></h1> <br />
       
        <p className={ray.bd}>  Mode of nutrition:  Herbivorous   </p> <br />
        
        
        <p className={ray.bd}>  Scientific Name: Tapirus indicus   </p> <br />
       
         
        
        <p className={ray.bd}>
 IUCN Status: Endangered  
         </p> <br />
         <p className={ray.bd}> Range: Indonesia, Thailand, Malaysia and Myanmar| </p>
        
     
        <br /><br />
        </div> 

        <div className={tp.s}></div>
          
      </div>
      </div>



             
          
    </div>
          
          </>
            
      
    )
}
export default Asia;