import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Asia from './components/animals/Asia';
import Africa from './components/animals/Africa';
import America from './components/animals/America';
import Australia from './components/animals/Australia';
import Antarctica from './components/animals/Antarctica';
import Europe from './components/animals/Europe';
import {
    createBrowserRouter,
    RouterProvider,
  } from "react-router-dom";
  const router = createBrowserRouter([
    {
      path: "/",
      element: <App/>,
    },
    {
        path: "asia",
        element: <Asia/>,
      },
      {
        path: "africa",
        element: <Africa/>,
      },
      {
        path: "americas",
        element: <America/>,
      },
      {
        path: "australia",
        element: <Australia/>,
      },
      {
        path: "antarctica",
        element: <Antarctica/>,
      },
      {
        path: "europe",
        element: <Europe/>,
      },
  ]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <RouterProvider router={router}/>

);


