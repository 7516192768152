import React from "react";
import e from './Eur.module.css';
import Navbar from "../navbar/Navbar";
import wol from './images/wolf.jpg';
import eu from './images/eus.jpg';
import ar from './images/arc.jpg';
import re from './images/rein.jpg';
import br from './images/br.jpg';
import './banners.css';
function Europe(){
    return(
        <>     
        <Navbar/>
        <div className='afb4'>
<h1 className='bannereu'> <br /> <br />    <br />Explore European Wildlife</h1>
</div>
<div className={e.first}>
       
       <br /><br />
       <br />
       <center><img src={ar} className={e.imga} /></center>
              <br /><br />
       
              <h1 className={e.hed}> Arctic Fox</h1>
              <br /><br />
              <p className={e.para}> Arctic fox or snow fox is a mammal inhabiting arctic regions. It is well adapted to live in cold environment. It has a thick double layered fur which keeps its warm. The fur changes its color from brown in summer to white in winter, providing camoflauge.

</p>
           <div className={e.cord}>
                   
                    <div className={e.bgc2}>
                <h1 className={e.title}> <b> Characteristics </b></h1> <br />
              
               <p className={e.bd}>  Mode of nutrition:  Carnivore  </p> <br />
               
               
               <p className={e.bd}>   Scientific Name: Vulpes lagopus
</p> <br />
                
               
               <p className={e.bd}>  IUCN Status: Least Concern
                </p> <br />
                <p className={e.bd}>  Range:  Greenland, Arctic regions of Europe, North America and Asia

</p>
<br /><br />
               </div> 
               <div className={e.s}></div>
               </div></div>
               <div className={e.first}>
       
       <br /><br />
       <br />
       <center><img src={br} className={e.imga} /></center>
              <br /><br />
       
              <h1 className={e.hed}> Brown Bear</h1>
              <br /><br />
              <p className={e.para}> The brown bear is a majesticly big and powerful animal and are also known as grizzly bear and kodiak bear. Brown bears can adapt to various environmental conditions. its size and color of fur varies in different regions, they are found in many colors - red-silver, yellow-brown, light-brown. It has a sharped curved claws which is used for digging and a unique hump over it shoulders. The brow cannot climb trees unlike other bear species

</p>
           <div className={e.cord}>
                   
                    <div className={e.bgc2}>
                <h1 className={e.title}> <b> Characteristics </b></h1> <br />
              
               <p className={e.bd}>  Mode of nutrition:  Omnivore </p> <br />
               
               
               <p className={e.bd}>   Scientific Name: Ursus arctos
</p> <br />
                
               
               <p className={e.bd}>  IUCN Status: Least Concern
                </p> <br />
                <p className={e.bd}>  Range:  Europe, North America and Asia

</p>
<br /><br />
               </div> 
               <div className={e.s2}></div>
               </div></div>
               <div className={e.first}>
       
       <br /><br />
       <br />
       <center><img src={re} className={e.imga} /></center>
              <br /><br />
       
              <h1 className={e.hed}> Reindeer </h1>
              <br /><br />
              <p className={e.para}> Reindeer is a deer inhabiting the arctic regions of Europe, North America, Greenland and Asia.
               Both male and female reindeers grow antlers, the antlers of the male is much larger than that of the female.
                The reindeer has a thick brownish-gray fur which keeps it warm in winter. The fur becomes lighter in winter providing
                 camoflauge in the snowy lands.
               The reindeer is the only deer species which is widely domesticated for milk, meat and pulling sleigh.
</p>
           <div className={e.cord}>
                   
                    <div className={e.bgc2}>
                <h1 className={e.title}> <b> Characteristics </b></h1> <br />
              
               <p className={e.bd}>  Mode of nutrition:  Herbivore </p> <br />
               
               
               <p className={e.bd}>   Scientific Name: Rangifer tarandus
</p> <br />
                
               
               <p className={e.bd}>  IUCN Status: Vulnerable
                </p> <br />
                <p className={e.bd}>  Range:  arctic regions of Europe, North America, Greenland and Asia

</p>
<br /><br />
               </div> 
               <div className={e.s3}></div>
               </div></div>
               <div className={e.first}>
       
       <br /><br />
       <br />
       <center><img src={wol} className={e.imga} /></center>
              <br /><br />
       
              <h1 className={e.hed}> Grey Wolf </h1>
              <br /><br />
              <p className={e.para}> The grey wolf is a beautiful and intelligent mammal native to Europe and North America. Grey wolves are highly adaptive and can resist and survive in various environmental conditions, they live packs and are highly social, they are territorial and defend and prevent their territory from other packs of wolves. They are famous for their loud vocalisations - howling, the wolves howl for communicating, defending their territory by scaring rivals, bonding and communication during hunting.

</p>

           <div className={e.cord}>
                   
                    <div className={e.bgc2}>
                <h1 className={e.title}> <b> Characteristics </b></h1> <br />
              
               <p className={e.bd}>  Mode of nutrition:  Carnivore </p> <br />
               
               
               <p className={e.bd}>   Scientific Name: Canis lupus
</p> <br />
                
               
               <p className={e.bd}>  IUCN Status: Least Concern
                </p> <br />
                <p className={e.bd}>  Range:  Europe, North America and Asia 

</p>
<br /><br />
               </div> 
               <div className={e.s5}></div>
               </div></div>
               <div className={e.first}>
       
       <br /><br />
       <br />
       <center><img src={eu} className={e.imga} /></center>
              <br /><br />
       
              <h1 className={e.hed}> European Bison </h1>
              <br /><br />
              <p className={e.para}>  The European Bison is the heaviest animal in Europe. It is also known the wisent. 
              The European Bison has a large head,curved horns and a hump over its shoulders.
               It has a thick fur which is yellowish-dark brown to light brown in color.



</p>

           <div className={e.cord}>
                   
                    <div className={e.bgc2}>
                <h1 className={e.title}> <b> Characteristics </b></h1> <br />
              
               <p className={e.bd}>  Mode of nutrition:  Herbivores </p> <br />
               
               
               <p className={e.bd}>   Scientific Name: : Bison bonasus
</p> <br />
                
               
               <p className={e.bd}>  IUCN Status: Near Threatened 
                </p> <br />
                <p className={e.bd}>  Range:  Eastern Europe
</p>
<br /><br />
               </div> 
               <div className={e.s4}></div>
               </div></div>
      
     
        </>
    );
}
export default Europe;