import React from "react";
import styles from './Hero.module.css';


const Hero = () => {
    return(
<div className={styles.hero}>
  <h1 className={styles.first}> <br /> <br /> World of Animals</h1>
</div>
    )
}
export default Hero;        