import React, { useState, useEffect } from 'react';
import './AnimalQuiz.css'; // Import CSS file for styling

// Define an array of questions and answers
const questions = [
  {
    question: 'What is the largest animal on Earth?',
    options: ['Elephant', 'Blue Whale', 'Giraffe', 'African Elephant'],
    answer: 'Blue Whale'
  },
  {
    question: 'Which animal can change its color to match its surroundings?',
    options: ['Octopus', 'Chameleon', 'Cuttlefish', 'Lion'],
    answer: 'Chameleon'
  },
  {
    question: 'What is the fastest land animal?',
    options: ['Cheetah', 'Lion', 'Gazelle', 'Leopard'],
    answer: 'Cheetah'
  },
  {
    question: 'What is the largest land animal?',
    options: ['Elephant', 'Giraffe', 'Hippo', 'Rhinoceros'],
    answer: 'Elephant'
  },
  {
    question: 'What is the slowest animal?',
    options: ['Snail','Koala','Sloth','Bear'],
    answer: 'Sloth'
  },
  {
    question: 'What is the only mammal capable of sustained flight?',
    options: ['Parrot','Bat','Crow','Owl'],
    answer: 'Bat'
  },
  {question: 'Which of the following animals is capable of regenerating lost limbs?',
 options: ['Axolotl','Gecko','Salamander','Alligator'],
 answer: 'Axolotl'
},
{question: 'What is the name of the largest species of lizard, native to Indonesia?',
options: ['Komodo Dragon',
'Monitor Lizard',
'Gila Monster',
,'Iguana'],
answer: 'Komodo Dragon'
}
  // Add more questions as needed
];

const AnimalQuiz = () => {
  const [questionsOrder, setQuestionsOrder] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [quizStarted, setQuizStarted] = useState(false);

  useEffect(() => {
    // Shuffle questions
    const shuffledQuestions = questions.sort(() => Math.random() - 0.5);
    setQuestionsOrder(shuffledQuestions);
  }, []);

  const startQuiz = () => {
    setQuizStarted(true);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleSubmit = () => {
    const currentQuestion = questionsOrder[currentQuestionIndex];
    const currentAnswer = currentQuestion.answer;

    if (selectedOption.toLowerCase() === currentAnswer.toLowerCase()) {
      setScore(score + 1);
      setMessage('Correct!');
    } else {
      setMessage(`Incorrect! The correct answer is "${currentAnswer}".`);
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex + 1 < questionsOrder.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setMessage('');
      setSelectedOption('');
    } else {
      setShowResult(true);
    }
  };

  const restartQuiz = () => {
    const shuffledQuestions = questions.sort(() => Math.random() - 0.5);
    setQuestionsOrder(shuffledQuestions);
    setCurrentQuestionIndex(0);
    setScore(0);
    setShowResult(false);
    setMessage('');
    setSelectedOption('');
    setQuizStarted(false);
  };

  // Check if the quiz has started
  if (!quizStarted) {
    return (
      <div className="start-container"> <br /><br />
      <center>  <h2 style={{color:'white'}}>Welcome to the WOA Animal Quiz</h2> </center>
      <br />
     <center>   <button onClick={startQuiz}>Start Quiz</button> </center>
     <br /><br />
      </div>
    );
  }

  // Check if questionsOrder is empty
  if (questionsOrder.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="quiz-container">
      {showResult ? (
        <div>
          <h2>Quiz Completed!</h2>
          <p>Your Score: {score} out of {questionsOrder.length}</p>
          <button onClick={restartQuiz}>Restart Quiz</button>
        </div>
      ) : (
        <div>
          <h2>Question {currentQuestionIndex + 1}</h2>
          <h3>{questionsOrder[currentQuestionIndex].question}</h3>
          <ul>
            {questionsOrder[currentQuestionIndex].options.map((option, index) => (
              <li key={index}>
                <button onClick={() => handleOptionSelect(option)}>{option}</button>
              </li>
            ))}
          </ul>
          <p className={message ? (message.includes('Correct') ? 'correct' : 'wrong') : ''}>{message}</p>
          {selectedOption && <button onClick={handleSubmit}>Submit</button>}
          {message && <button onClick={handleNextQuestion}>Next</button>}
        </div>
      )}
    </div>
  );
};

export default AnimalQuiz;
