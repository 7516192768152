import React, { useState } from "react";
import styles from './Navbar.module.css';
import {AiOutlineMenu,AiOutlineClose} from 'react-icons/ai'
import { Link } from "react-router-dom";
import logo from './woalogo.jpg'

 
const Navbar = () => {
    const [nav, setNav] = useState(false)
    return(
        <header className={styles.navbar}>
             <img src={logo} height={60} alt="/" />
    
            <nav>
                <ul className={nav ? [styles.menu, styles.active].join(' ') : [styles.menu]}>
                    <li >
                    <a href="/"> Home</a>
                        </li>
                       
                        <li>  <Link to="/asia"> Asia</Link>
                             </li>
                             <li>
                      <Link to='/africa'> Africa</Link>
                        </li>
                             <li>
                               <Link to="/americas"> Americas</Link>
                             </li>
                             <li>
                                <Link to="/australia"> Australia</Link>
                             </li>
                             <li> <Link to="/antarctica"> Antarctica</Link></li>
                             <li> <Link to="/europe"> Europe</Link></li>
                            
                </ul>
            </nav>
            <div onClick={() => setNav(!nav)} className={styles.mobile_btn}>
             {nav ? <AiOutlineClose size={25}/> :
                <AiOutlineMenu size={25}/> }
            </div>
        </header>
    )
}
export  default Navbar;