import React from "react";
import Navbar from "./components/navbar/Navbar";
import Hero from "./components/hero/Hero";
import Image from "./components/find/Image";
import AnimalQuiz from "./components/hero/Animalquiz";



function App() {
  return (
   <>
      <Navbar/>
    
   <Hero/>
   <Image/>
   
  
   

   </>
  );
}

export default App;
