import React, {useEffect, useRef, useState } from 'react';
import  './Image.css';
import image1 from './Image1.jpg';
import image2 from './images2.jpg';
import atlas from './atlas.jpg';
import AnimalQuiz from '../hero/Animalquiz';



const Image = () => {
  
 
    const images = [image1, image2];
    const [currentIndex, setCurrentIndex] = useState(0);
  
    const goToNextSlide = () => {
      const newIndex = (currentIndex + 1) % images.length;
      setCurrentIndex(newIndex);
    };
  
    const goToPrevSlide = () => {
      const newIndex = (currentIndex - 1 + images.length) % images.length;
      setCurrentIndex(newIndex);
    };
  

  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);
  return (
  
    <div className="image">
      <div className="first">
        <br />
        <div className={`section ${isVisible ? 'visible' : ''}`} ref={sectionRef}> <p>   <b>
            “World of Animals” is a website dedicated to animal lovers and enthusiasts. 
Come and explore this wonderland of animals spread across the vastness of continents<br /><br />
- Stardust Corporation India  (Navneet) </b>         
</p> </div>
      <br /><br />
          <p style={{fontFamily:"sans-serif",fontSize:"1.2rem"}}> 

     <i> <b>
          “If we can teach people about wildlife, they will be touched. Share my   <br />wildlife with me. Because humans want to save things that they love.” 
           
          <br /> <br />
― Steve Irwin 
</b></i>
          </p>
         
          
   
    </div>    
    
    <AnimalQuiz/>

  </div>
      
      

  );
};

export default Image;